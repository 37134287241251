import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import OfficeStationaryImg from "../../components/images/printing/office-stationary"

export default function OfficeStationary() {
    return (
        <>
            <Layout>
                <SEO title="Office Stationery" />
                <Display
                    title="Office Stationery "
                    description="Enjoy non-stop branding and inspire confidence at your place of work – with everyday office essentials that are affordably designed with your logo to boost your business visibility."
                    prev="/printing/business-cards"
                    next="/printing/brochures"
                    image={<OfficeStationaryImg />}
                />
            </Layout>                
        </>
    )
}
